<template>
  <h1>Not Found</h1>
</template>

<script>
export default {
  name: "not-found"
}
</script>

<style scoped>

</style>
